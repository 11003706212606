import "bootstrap";
import $ from 'jquery'
// import  'jquery.marquee';
import Swiper, {Autoplay} from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import ymaps from 'ymaps';
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay]);
// var Inputmask = require('inputmask');
import Inputmask from "inputmask";

$('#burger').on('click', function (e){
    e.preventDefault()
    e.stopPropagation()
    $('#main-menu').addClass('active')
});
$('#close-menu').on('click', function (){
    $('#main-menu').removeClass('active')
});
$('#get-consultation').on('click', function (e){
    e.preventDefault()
    e.stopPropagation()
    $('#consultation').addClass('active')
});
$('.footer-link-accordion-title').on('click', function (){
    $(this).parent().toggleClass('active')
});
$('.mobile-accordion-title').on('click', function (){
    $(this).parent().toggleClass('active')
});
var calc_ch = 0;
$('.calculator-bookmark').on('click', function (){
    calc_ch = $(this).index();
    $('#calculator-modal').addClass('active');
    // $('.calculator-bookmark').removeClass('active');
    // $(this).addClass('active');
    // $('.calculator-form-block').removeClass('active');
    // $('.calculator-form-block').eq($(this).index()).addClass('active');
});
$('#calculator-modal .yes-button').on('click', function (e){
    e.preventDefault()
    e.stopPropagation()
    $('#calculator-modal').removeClass('active');
    $('.calculator-bookmark').removeClass('active');
    $('.calculator-bookmark').eq(calc_ch).addClass('active');
    $('.calculator-form-block').removeClass('active');
    $('.calculator-form-block').eq(calc_ch).addClass('active');
});

$('.vacancy-form-btn').on('click', function (){
    $('#success-job-modal').addClass('active');
    return false;
});
$('.be-partner-btn').on('click', function (){
    $('#be-partner').addClass('active');
    return false;
});
$('.auth-btn').on('click', function (){
    $('#registration').addClass('active');
    return false;
});
// $('.calculator-right-content .red-button').on('click', function (){
//     $('#calculator-modal').addClass('active');
//     return false;
// });
$('.get-consultation').on('click', function (){
    $('#consultation').addClass('active');
    return false;
});
$('.menu-auth').on('click', function (){
    $('#main-menu').removeClass('active')
    $('#registration').addClass('active');
});
$('.close-modal').on('click', function (){
    $('.modal').removeClass('active');
});
$('.modal .cancel-button').on('click', function (){
    $('.modal').removeClass('active');
});

$('.open-menu-list').on('click', function (e){
    e.preventDefault()
    e.stopPropagation()
    $(this).toggleClass('active');
    if ($(this).hasClass('active')){
        $('#'+$(this).attr('data-list')).show();
    }else{
        $('#'+$(this).attr('data-list')).hide();
    }
});

var oldform = null;
// $('.modal-form button').on('click', function (){
//     oldform = $(this).parents('.modal');
//     $('.modal').removeClass('active');
//     $('#error-modal').addClass('active')
//     return false;
// })
$('.again-button').on('click', function (){
    $('.modal').removeClass('active');
    oldform.addClass('active')
})

$('.input-block.select-input-block .select-item').on('click', function (){
    $(this).toggleClass('active')
    return false;
})
$('.select-item.checkbox').on('click', function (){
    $(this).toggleClass('active')
    return false;
})

var features_swiper = null

if ($('.about-top').length == 0){
     features_swiper = new Swiper('#features-slider', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        pagination: {
            el: "#features-slider-dots",
            clickable: true
        },
    });
    features_swiper.autoplay.stop()

}

var gray_block_swiper1;
var gray_block_swiper2;
var gray_block_swiper3;
var gray_block_swiper4;
var gray_block_swiper5;
var way_swiper1;
var way_swiper2;
var way_swiper3;

var scheme_video
if ($('#we-help-video').length > 0){
    scheme_video = document.getElementById('we-help-video');
}

if (document.body.clientWidth < 1024){
    $('#products-list').addClass('slider-block');
    const products_swiper = new Swiper('#products-list', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: ".products-pagination",
            type: "progressbar",
        },
    });
    $('#home-about-items').addClass('slider-block');
    const about_swiper = new Swiper('#home-about-items', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: ".about-pagination",
            type: "progressbar",
        },
    });

    $('.gray-blocks-list').addClass('slider-block');

    setTimeout(function (){
        $('.gray-blocks-list').each(function (){
            var hh = 0;
            $(this).find('.gray-block').each(function (){
                if (hh < $(this).height()){
                    hh = $(this).height();
                }
            });
            $(this).find('.gray-block').height(hh);
        });
    },1000)

    gray_block_swiper1 = new Swiper('#gray-blocks-list1', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#gray-blocks-pagination1",
            type: "progressbar",
        },
    });
    gray_block_swiper2 = new Swiper('#gray-blocks-list2', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#gray-blocks-pagination2",
            type: "progressbar",
        },
    });
    gray_block_swiper3 = new Swiper('#gray-blocks-list3', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#gray-blocks-pagination3",
            type: "progressbar",
        },
    });
    gray_block_swiper4 = new Swiper('#gray-blocks-list4', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#gray-blocks-pagination4",
            type: "progressbar",
        },
    });
    gray_block_swiper5 = new Swiper('#gray-blocks-list5', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#gray-blocks-pagination5",
            type: "progressbar",
        },
    });

    const fly_swiper = new Swiper('.fly-logo-block', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: ".fly-pagination",
            type: "progressbar",
        },
    });



    way_swiper1 = new Swiper('#mobile-solution-way-items1', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#way-pagination1",
            type: "progressbar",
        },
    });
    way_swiper2 = new Swiper('#mobile-solution-way-items2', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#way-pagination2",
            type: "progressbar",
        },
    });
    way_swiper3 = new Swiper('#mobile-solution-way-items3', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
            el: "#way-pagination3",
            type: "progressbar",
        },
    });

    const about_blog_swiper = new Swiper('.about-blog-list', {
        slidesPerView: 'auto',
        spaceBetween: 16,
        pagination: {
            el: ".blog-list-pagination",
            type: "progressbar",
        },
    });

    $('.long-email-input').attr('placeholder', 'E-mail');

}


if ($('.logos-line').length > 0){
    setTimeout(function (){
        $('.logos-line').width($('.logos-list').eq(0).width());
        $('.logos-list').addClass('animate');
    }, 1000)

}

// var $mq = $('#logos').marquee({
//     duplicated: true,
//     gap: 0,
//     duration: 15000,
//     delayBeforeStart: 0,
// });


$('body').on('mousemove', function (e){
    var posx = e.pageX;
    var posy = e.pageY;
    var ww = $('.dark-header').width();
    var hh = $('.dark-header').height();
    if (posx < document.body.clientWidth / 2){
        $('.cube1').css({'transform': 'translateX(-'+(ww/2 - posx)/20+'px) translateY('+(hh - posy)/20+'px)'});
        $('.duga13').css({'transform': 'translateY(-'+(ww/2 - posx)/50+'px)'});
        $('.duga11').css({'transform': 'translateY('+(ww/2 - posx)/50+'px)'});
    }else{
        $('.cube3').css({'transform': 'translateX(-'+(posx - ww/2)/20+'px) translateY(-'+(hh - posy)/20+'px)'});
        $('.duga23').css({'transform': 'translateY('+(posx - ww/2)/50+'px)'});
        $('.duga21').css({'transform': 'translateY(-'+(posx - ww/2)/50+'px)'});
    }
});

$(window).on('scroll', function (){
    scrollPage();
})

$('body').on('mousewheel', function (){
    scrollPage();
})

if ($('.dark-header').length == 0){
    $('header').addClass('whitebgalways');
}

function scrollPage(){
    var st = $(window).scrollTop();
    $('.cube-scroll').css({'transform': 'translateY(-'+st/6+'px)'});
    if (st >  0){
        if (!$('header').hasClass('darkbg')){
            $('header').addClass('whitebg');
        }
    }else{
        $('header').removeClass('whitebg');
    }

    if (st>0){
        $('.whitebgalways').addClass('scrolled')
    }else{
        $('.whitebgalways').removeClass('scrolled')
    }


    if ($('.solution-way').length>0){
        $('.page-content').eq($('#bookmarks a.active').index()).find('.solution-way-item').each(function (){
            if ($(this).offset().top < st + document.body.clientHeight/2 ){
                $(this).addClass('active');
                $('.page-content').eq($('#bookmarks a.active').index()).find('.way-dot').eq($(this).index()).addClass('active');
            }else{
                $(this).removeClass('active');
                $('.page-content').eq($('#bookmarks a.active').index()).find('.way-dot').eq($(this).index()).removeClass('active');
            }
        });
        if ($('.page-content').eq($('#bookmarks a.active').index()).find('.solution-way-item').eq(0).hasClass('active')){
            $('.page-content').eq($('#bookmarks a.active').index()).find('.way-path-punctir').addClass('active');
        }else{
            $('.page-content').eq($('#bookmarks a.active').index()).find('.way-path-punctir').removeClass('active');
        }
    }

    if (document.body.clientWidth > 800) {
        if ($('.fly-logo-block').length > 0) {
            if (st > $('.fly-logo-block').position().top - document.body.clientHeight / 2) {
                $('.fly-logo-item').addClass('active');
            } else {
                $('.fly-logo-item').removeClass('active');
            }
            if (st > $('.fly-logo-block').position().top - document.body.clientHeight / 3) {
                $('.fly-logo-item').css({'margin-left': -(st - ($('.fly-logo-block').position().top - document.body.clientHeight / 3)) / 5});
            } else {
                $('.fly-logo-item').css({'margin-left': 0});
            }
        }
    }

    if ($('#features-slider').length > 0 && !$('#features-slider-dots').hasClass('active')){
        if (st > $('#features-slider').offset().top - document.body.clientHeight / 2){
            $('#features-slider-dots').addClass('active')
            features_swiper.autoplay.start()
        }
    }

    if ($('#we-help-video').length > 0){
        if (st > $('#we-help-video').offset().top - document.body.clientHeight / 2){
            if (!$('#we-help-video').hasClass('start-video')){
                $('#we-help-video').addClass('start-video')
                scheme_video.play()
            }
        }
    }

}

$('.header-select').on('click', function (e){
    e.stopPropagation()
    e.preventDefault()
    if ($(this).hasClass('active')){
        $('header').removeClass('darkbg')
        $(this).removeClass('active')
        $('.open-header-menu').eq($(this).index()).removeClass('active')
        scrollPage()
    }else{
        $('header').removeClass('whitebg')
        $('header').addClass('darkbg')
        $('.header-select').removeClass('active')
        $('.open-header-menu').removeClass('active')
        $(this).addClass('active')
        $('.open-header-menu').eq($(this).index()).addClass('active')
    }
})

$('#bookmarks a').on('click', function (){
    $('#bookmarks a').removeClass('active');
    $(this).addClass('active');
    $('.page-content').hide();
    $('.page-content').eq($(this).index()).show();

    if (document.body.clientWidth <= 800) {
        if ($('#gray-blocks-list1').length > 0) {
            gray_block_swiper1.update()
        }
        if ($('#gray-blocks-list2').length > 0) {
            gray_block_swiper2.update()
        }
        if ($('#gray-blocks-list3').length > 0) {
            gray_block_swiper3.update()
        }
        if ($('#gray-blocks-list4').length > 0) {
            gray_block_swiper4.update()
        }
        if ($('#gray-blocks-list5').length > 0) {
            gray_block_swiper5.update()
        }
    }
    setTimeout(function (){
        $('.gray-block').css({'height': 'auto'});
        $('.gray-blocks-list').each(function (){
            var hh = 0;
            $(this).find('.gray-block').each(function (){
                if (hh < $(this).height()){
                    hh = $(this).height();
                }
            });
            $(this).find('.gray-block').height(hh);
        });
    },100)

    if ($('#mobile-solution-way-items1').length > 0) { way_swiper1.update() }
    if ($('#mobile-solution-way-items2').length > 0) { way_swiper2.update() }
    if ($('#mobile-solution-way-items3').length > 0) { way_swiper3.update() }

})

// $('.blog-filter-bookmarks-links a').on('click', function (){
//     $('.blog-filter-bookmarks-links a').removeClass('active');
//     $(this).addClass('active');
//     $('.blog-list').hide();
//     $('.blog-list').eq($(this).index()).show();
//     return false;
// })

$('.video-play-btn').on('click', function (){
    $(this).parents('.video-item').addClass('play');
    $(this).parents('.video-item').find('iframe').attr('src', $(this).parents('.video-item').attr('data-video-source'))
    return false;
})

$('.calculator-left-bookmarks span').on('click', function (){
    $(this).parents('.calculator-left').find('.calculator-left-bookmarks span').removeClass('active')
    $(this).addClass('active')
    $(this).parents('.calculator-left').find('.calculator-left-items input').val('0');
    calculate();

    return false;
})


$('.select-title').on('click', function (e){
    e.stopPropagation();
    e.preventDefault();
    if ($(this).parent().hasClass('active')){
        $('.blog-list-filter-select').removeClass('active');
        $('.vacancies-list-filter-select').removeClass('active');
    }else{
        $('.blog-list-filter-select').removeClass('active');
        $('.vacancies-list-filter-select').removeClass('active');
        $(this).parent().addClass('active');

    }
    return false;
})
$('body').on('click', function (){
    $('.blog-list-filter-select').removeClass('active');
    $('.vacancies-list-filter-select').removeClass('active');
    $('.select-input-block').removeClass('active');
    $('.open-header-menu').removeClass('active');
    $('#main-menu').removeClass('active')
    $('header').removeClass('whitebg')
    $('header').removeClass('darkbg')
    $('.header-select').removeClass('active')
    var st = $(window).scrollTop();
    if (st >  0){
        if (!$('header').hasClass('darkbg')){
            $('header').addClass('whitebg');
        }
    }else{
        $('header').removeClass('whitebg');
    }

})

var oldhash = window.location.hash
$(window).on('hashchange', function () {
    // $('html, body')
    var newhash = window.location.hash
    if (oldhash!='' && oldhash != newhash){
        $(window).scrollTop(0);
        window.location.reload();
    }
});

if ($('#map').length > 0){
    ymaps
        .load()
        .then(maps => {
            const map = new maps.Map('map', {
                center: [55.660760, 37.543560],
                zoom: 15,
                controls: []
            });
            map.controls.add('zoomControl');
            map.behaviors.disable('scrollZoom');
            var myPlacemark = new maps.Placemark([55.660760, 37.543560], null,{
                iconLayout: 'default#image',
                iconImageHref: "img/contacts_icon/marker.png",
                iconImageSize: [48, 48],
                iconImageOffset: [-24, -48]
            });
            map.geoObjects.add(myPlacemark);
            var myPlacemark2 = new maps.Placemark([55.623198, 37.621875], null,{
                iconLayout: 'default#image',
                iconImageHref: "img/contacts_icon/marker.png",
                iconImageSize: [48, 48],
                iconImageOffset: [-24, -48]
            });
            map.geoObjects.add(myPlacemark2);
        })
        .catch(error => console.log('Failed to load Yandex Maps', error));

}


// var calculator = [0,0,0,0,0];

$('.calculator-plus').on('click', function (){
    if ($(this).parents('.calculator-left-item').hasClass('add-node')) {
        if ($('.kub-cpu').find('input').val() == 0){
            $('.kub-cpu').find('input').val(2)
            $('.kub-ram').find('input').val(2)
            if ($(this).parents('.calculator-left-item').hasClass('kub-ssd')){
                $('.kub-ssd').find('input').val(10)
                $('.kub-sata').find('input').val(0)
            }else if ($(this).parents('.calculator-left-item').hasClass('kub-sata')){
                $('.kub-ssd').find('input').val(0)
                $('.kub-sata').find('input').val(10)
            }else{
                $('.kub-ssd').find('input').val(10)
                $('.kub-sata').find('input').val(0)
            }
            $('.kub-node').find('input').val(2)
        }else{
            if ($(this).parents('.calculator-left-item').hasClass('kub-ssd')){
                if ($('.kub-ssd').find('input').val()*1 == 0){
                    $('.kub-ssd').find('input').val(10)
                    $('.kub-sata').find('input').val(0)
                }else if ($('.kub-sata').find('input').val()*1 == 0){
                    var count = $(this).parent().find('input').val() * 1
                    count++;
                    $(this).parent().find('input').val(count);
                }
            }else if ($(this).parents('.calculator-left-item').hasClass('kub-sata')){
                if ($('.kub-sata').find('input').val()*1 == 0){
                    $('.kub-ssd').find('input').val(0)
                    $('.kub-sata').find('input').val(10)
                }else if ($('.kub-ssd').find('input').html()*1 == 0){
                    var count = $(this).parent().find('input').val() * 1
                    count++;
                    $(this).parent().find('input').val(count);
                }
            }else{
                var count = $(this).parent().find('input').val() * 1
                count++;
                $(this).parent().find('input').val(count);
            }
        }
    }else{
        var count = $(this).parent().find('input').val() * 1
        count++;
        $(this).parent().find('input').val(count);
    }
    calculate();
});
$('.calculator-minus').on('click', function (){
    if ($(this).parents('.calculator-left-item').hasClass('add-node')) {
        if ($(this).parents('.calculator-left-item').hasClass('kub-ssd')){
            if ($('.kub-sata').find('input').val()*1 == 0){
                var count = $(this).parent().find('input').val() * 1
                count--;
                if (count < 10 && count>= 0) {
                    count = 10;
                }
                if (count < 0) {
                    count = 0;
                }
                $(this).parent().find('input').val(count);
            }
        }else if ($(this).parents('.calculator-left-item').hasClass('kub-sata')){
            if ($('.kub-ssd').find('input').val()*1 == 0){
                var count = $(this).parent().find('input').val() * 1
                count--;
                if (count < 10 && count>= 0) {
                    count = 10;
                }
                if (count < 0) {
                    count = 0;
                }
                $(this).parent().find('input').val(count);
            }
        }else if ($(this).parents('.calculator-left-item').hasClass('kub-node')){
            var count = $(this).parent().find('input').val() * 1
            count--;
            if (count < 2 && count>= 0) {
                count = 0;
                $('.kub-ssd').find('input').val(0);
                $('.kub-sata').find('input').val(0);
                $('.kub-cpu').find('input').val(0);
                $('.kub-ram').find('input').val(0);
                $('.kub-node').find('input').val(0);
            }else{
                $(this).parent().find('input').val(count);
            }
            if (count < 0) {
                count = 0;
            }
        }else{
            var count = $(this).parent().find('input').val() * 1
            count--;
            if (count < 2 && count>= 0) {
                count = 2;
            }
            if (count < 0) {
                count = 0;
            }
            $(this).parent().find('input').val(count);
        }
    }else{
        var count = $(this).parent().find('input').val() * 1
        count--;
        if (count < 0) {
            count = 0;
        }
        $(this).parent().find('input').val(count);
    }
    console.log(count);
    if (count >= 0){
        calculate();
    }
});
$('.toggle-element').on('click', function (){
    $(this).toggleClass('active');
    calculate();
});
$('.calculator-right-tumbler').on('click', function (){
    $('.calculator-right-tumbler').toggleClass('month')
    calculate();
});
$('.calculator-left-item input').on('change', function (){
    calculate();
});

function calculate(){
    var full_sum = 0;
    $('.calculator-form-block').each(function () {
        var part_sum = 0;
        $(this).find('.calculator-left-item').each(function () {
            var cnt = 0;
            var price = 0;
            if ($(this).find('.toggle-element').length == 0 && !$(this).hasClass('kub-node')){
                cnt = $(this).find('.calculator-left-item-counter input').val() * 1;
                price = $(this).attr('data-price')*1;
                part_sum+=cnt*price;
            }else if ($(this).hasClass('kub-node') && $(this).find('.calculator-left-item-counter input').val()*1 > 0){
                cnt = $(this).find('.calculator-left-item-counter input').val() * 1 - 1;
                price = $(this).attr('data-price')*1;
                part_sum+=cnt*price;
                if ($('.kub-ssd').find('input').val()* 1 > 0){
                    part_sum+=$('.kub-ssd').attr('data-price') * ($('.kub-ssd').find('input').val() - 10)*2 + $('.kub-ssd').attr('data-price')*(cnt+1);
                }
                if ($('.kub-sata').find('input').val()* 1 > 0){
                    part_sum+=$('.kub-sata').attr('data-price') * ($('.kub-ssd').find('input').val() - 10)*2 + $('.kub-sata').attr('data-price')*(cnt+1);
                }
            }else{
                if ($(this).find('.toggle-element').hasClass('active')){
                    cnt = 1;
                    price = $(this).attr('data-price')*1;
                    part_sum+=price*cnt;
                }
            }
            if ($(this).hasClass('add-esxi')){
                if (cnt > 0){
                    $('.is-esxi').find('.calculator-left-item-counter span').html(1);
                }else{
                    $('.is-esxi').find('.calculator-left-item-counter span').html(0);
                }
            }
        });
        full_sum+=part_sum;
        $(this).find('.part-sum').html(moneyFormat(part_sum) + ' ₽');

    });
    if ($('.calculator-right-tumbler').hasClass('month')){
        $('.full-sum').html(moneyFormat(full_sum) + ' ₽');
    }else{
        $('.full-sum').html(moneyFormat(full_sum/(365/12)) + ' ₽');
    }

}
function moneyFormat(n) {
    return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace('.', ',');
}

if (window.location.hash != ''){
    var bmhash = window.location.hash.split('#')[1]*1 - 1;
    $('#bookmarks a').eq(bmhash).click();
    // $('.blog-filter-bookmarks-links a').eq(bmhash).click();
}


var selector = document.getElementsByClassName("phone-input");
var im = new Inputmask("+7 (999) 99-99-999");
if ($('.phone-input').length > 0){
    im.mask(selector);
}
// $('.phone-input').inputmask("+7 (999) 99-99-999");
function isEmailValid(email) {
    const emailRegexp = new RegExp(
        /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
    )

    return emailRegexp.test(email)
}

$('#form-14 button').on('click', function (){
    var par = $(this).parents('#form14day');
    var page = par.attr('data-page');
    par.find('.error').removeClass('error');
    var data = {};
    data.page = page
    if (par.find('.name-input').length > 0){
        if (par.find('.name-input').val() == ''){
            $('.name-input').parent().addClass('error');
        }else{
            data.name = par.find('.name-input').val();
        }
    }
    if (par.find('.phone-input').length > 0){
        if (par.find('.phone-input').val() == ''){
            $('.phone-input').parent().addClass('error');
        }else{
            data.phone = par.find('.phone-input').val();
        }
    }
    if (par.find('.company-input').length > 0){
        if (par.find('.company-input').val() == ''){
            $('.company-input').parent().addClass('error');
        }else{
            data.company = par.find('.company-input').val();
        }
    }
    if (par.find('.task-input').length > 0){
        if (par.find('.task-input').val() == ''){
            $('.task-input').parent().addClass('error');
        }else{
            data.task = par.find('.task-input').val();
        }
    }
    if (par.find('.question-input').length > 0){
        data.question = par.find('.question-input').val();
    }
    if (par.find('.email-input').length > 0){
        if (!isEmailValid(par.find('.email-input').val())){
            $('.email-input').parent().addClass('error');
        }else{
            data.email = par.find('.email-input').val();
        }
    }

    if (par.find('.error').length == 0){
        $('#form-14 button').attr('disabled', true)
        $.post( "https://iteco.cloud/send14.php", data, function(data) {
            if (data == 'Success'){
                $('#success-modal').addClass('active');
            }else{
                $('#error-modal').addClass('active');
            }
            $('#form-14 button').attr('disabled', false)
        });
    }
    return false;
});


$('#consultation-form button').on('click', function (){
    var par = $(this).parents('#consultation');
    var page = par.attr('data-page');
    par.find('.error').removeClass('error');
    var data = {};
    data.page = page
    if (par.find('.name-input').length > 0){
        if (par.find('.name-input').val() == ''){
            $('.name-input').parent().addClass('error');
        }else{
            data.name = par.find('.name-input').val();
        }
    }
    if (par.find('.phone-input').length > 0){
        if (par.find('.phone-input').val() == ''){
            $('.phone-input').parent().addClass('error');
        }else{
            data.phone = par.find('.phone-input').val();
        }
    }
    if (par.find('.company-input').length > 0){
        if (par.find('.company-input').val() == ''){
            $('.company-input').parent().addClass('error');
        }else{
            data.company = par.find('.company-input').val();
        }
    }
    if (par.find('.task-input').length > 0){
        if (par.find('.task-input').val() == ''){
            $('.task-input').parent().addClass('error');
        }else{
            data.task = par.find('.task-input').val();
        }
    }
    if (par.find('.question-input').length > 0){
        data.question = par.find('.question-input').val();
    }
    if (par.find('.email-input').length > 0){
        if (!isEmailValid(par.find('.email-input').val())){
            $('.email-input').parent().addClass('error');
        }else{
            data.email = par.find('.email-input').val();
        }
    }

    if (par.find('.error').length == 0){
        $('#consultation button').attr('disabled', true)
        $.post( "https://iteco.cloud/send14.php", data, function(data) {
            $('.modal').removeClass('active');
            if (data == 'Success'){
                $('#success-modal').addClass('active');
            }else{
                $('#error-modal').addClass('active');
            }
            $('#consultation button').attr('disabled', false)
        });
    }
    return false;
});

